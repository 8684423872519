import React from "react"
import styled from "styled-components"
import {colors} from "../variables";


const SectionTitle = styled.h3`
  font-size: calc(20px + (34 - 20) * ((100vw - 300px) / (1600 - 300)));
  color: ${colors.lightGreyText};
  text-transform:uppercase;
`

export default SectionTitle
